import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Autocomplete, Box, Button, CircularProgress, Divider, dividerClasses, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck, GetCollegeId, PAGE_SIZE } from "../../constants/options";
import VmButton from "../../components/shared-button";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import Breadcrumb from "../../components/shared-breadcrumb";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL, THEME_BUTTON, THEME_COLOR, THEME_HIGHLIGHT } from "../../constants/style";
import IosShareIcon from '@mui/icons-material/IosShare';
import SlideDown from "react-slidedown";
import { DateUtils } from "../../utilities/date";
import { toJS } from "mobx";
import { Link } from "gatsby";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: THEME_BUTTON,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const WorkingHoursSummaryPage = observer(() => {
  const { rootStore, permissionStore, teacherStore, academicStore } = useStores();
  const [targetTrainer, setTargetTrainer] = useState<number>(0);
  const [searchKey, setSearchKey] = useState<string>("");
  const [searchDetailKey, setSearchDetailKey] = useState<string>("");
  const [startDate, setStartDate] = useState<any>(new Date(
    new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState<any>(new Date
    (new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]);
  const [fold, setFold] = useState<number>(-1);
  const [sortMode, setSortMode] = useState<string>("0");
  const [processedDetailList, setProcessDetailList] = useState<any>([]);
  const [refreshDisplay, setRefreshDisplay] = useState<any>([]);

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.TEACHER) == 0
      || teacherStore.teacherProfileList.data.length != ApiDataResponseListLengthCheck(ApiDataListType.TEACHER)) {
      teacherStore.getTeacherProfileList(0, 0)
        .then((res) => {
          if (res.data.length > 0) onSearchWorkingSummary();
        });
    }
    onSearchWorkingSummary();
  }, []);

  const onChangeTrainerAutoComplete = (trainerName: string) => {
    if (teacherStore.teacherProfileList.data.length > 0) {
      let trainer = teacherStore.teacherProfileList.data.find(t => trainerName === t.firstName + " " + t.lastName);
      if (trainer) setTargetTrainer(trainer.id);
      else setTargetTrainer(0);
    }
  }

  const onSearchWorkingSummary = () => {
    teacherStore.getTrainerWorkingHoursOverview({
      startDate,
      endDate: appendTimeStringToDate(endDate),
      trainerIdList: targetTrainer == 0 ? teacherStore.teacherProfileList.data.map(t => t.id) : [targetTrainer]
    });
    setSearchKey("");
  }

  const onDisplayWorkingDetail = (index: number, trainerId: number) => {
    setFold(index);
    setSearchDetailKey("");
    teacherStore.getTrainerWorkingHoursDetailByTrainerId({
      startDate,
      endDate: appendTimeStringToDate(endDate),
      trainerId,
    }).then(() => onSortDetailList(+sortMode))
  }

  const onGenerateExcelFile = () => {
    teacherStore.generateTrainerWorkingHoursOverviewExcel({
      startDate,
      endDate: appendTimeStringToDate(endDate),
      trainerIdList: targetTrainer == 0 ? teacherStore.teacherProfileList.data.map(t => t.id) : [targetTrainer]
    });
  }

  const appendTimeStringToDate = (date: string) => {
    return date + "T23:59:59";
  }

  const onChangeSortMode = (event: SelectChangeEvent) => {
    let option = event.target.value as string;
    setSortMode(option);
    onSortDetailList(+option);
  };

  const onSortDetailList = (mode: number) => {
    const latestDetailList = teacherStore.workingDetailList.reverse();
    switch (mode) {
      case 0: setProcessDetailList(latestDetailList);
        break;
      case 1: setProcessDetailList(teacherStore.workingDetailList);
        break;
    }
    setRefreshDisplay([]);
  }

  return (
    <ContentLayout
      pageName={'Working Hours Summary'}
      pageHeading={'Working Hours Summary'}
      breadCrumb={[{}]}
      showHeadingButton={false}
    >
      {teacherStore.loading ?
        <div className="text-center mb-4"><Skeleton height={50} /></div>
        : <div className="grid grid-cols-7 gap-4">
          <TextField
            value={startDate}
            label={t('START_DATE')}
            type="date"
            variant="outlined"
            sx={{ width: '100%', marginBottom: 2, background: 'white' }}
            onChange={(value) => setStartDate(value.target.value)}
            className="col-span-2"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            value={endDate}
            label={t('END_DATE')}
            type="date"
            variant="outlined"
            sx={{ width: '100%', marginBottom: 2, background: 'white' }}
            onChange={(value) => setEndDate(value.target.value)}
            className="col-span-2"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className="col-span-2">
            <Autocomplete
              freeSolo
              id="combo-box-demo"
              value={teacherStore.teacherProfileList.data.find(t => t.id == targetTrainer) ? teacherStore.teacherProfileList.data.find(t => t.id == +targetTrainer).firstName + " " + teacherStore.teacherProfileList.data.find(t => t.id == +targetTrainer).lastName : ""}
              options={teacherStore.teacherProfileList.data.map((t) => ({ label: t.firstName + " " + t.lastName, id: t.id }))}
              sx={{ width: '100%', background: "white" }}
              onInputChange={(event, newInputValue) => onChangeTrainerAutoComplete(newInputValue)}
              renderInput={(params) =>
                <TextField  {...params} label={"All " + t('TEACHER')} />}
            />
          </div>
          <Button
            variant="outlined"
            sx={{ marginBottom: 2 }}
            disabled={new Date(startDate) > new Date(endDate)}
            onClick={onSearchWorkingSummary}
          >
            {t('SEARCH')}
          </Button>
        </div>}

      <div className="flex mb-4">
        <TextField
          value={searchKey}
          label={'Search Trainer by Name'}
          variant="outlined"
          sx={{ width: 300, background: 'white', marginRight: 2 }}
          onChange={(value) => setSearchKey(value.target.value)}
          size="small"
        />
        {(!teacherStore.loadingWorkingSummary && teacherStore.workingSummaryList.length > 0) &&
          <Button
            variant="outlined"
            disabled={teacherStore.downloadExcel}
            onClick={onGenerateExcelFile}
          >
            {teacherStore.downloadExcel ? <CircularProgress />
              : <Box sx={ITEM_PERFECT_INLINED}>
                <IosShareIcon />
                <Typography marginLeft={1} variant="body2">Export as Excel File</Typography></Box>}
          </Button>}
      </div>
      <VmTable
        loading={teacherStore.loading || teacherStore.loadingWorkingSummary}
        page={0}
        thead={["Trainer Name", 'Total Planned Hours', 'Total Actual Hours', 'Total No. of Visits', t('ACTION')]}
        tbody={teacherStore.workingSummaryList.filter(r => searchKey === "" ? r.trainerName !== "" : r.trainerName.toLowerCase().includes(searchKey.toLowerCase())).length > 0 &&
          teacherStore.workingSummaryList.filter(r => searchKey === "" ? r.trainerName !== "" : r.trainerName.toLowerCase().includes(searchKey.toLowerCase()))
            .map((record: any, index: number) => (
              <>
                <Box marginY={1} />
                <tr key={`working_record_${index}`}>
                  <td className={`${TD_FIRST} pl-4 ${fold == index && 'rounded-l-none'}`}>{record.trainerName}</td>
                  <td className={`${TD_NORMAL} pl-4`}>{record.plannedHours.toFixed(record.plannedHours % 1 != 0 && 2)} hour(s)</td>
                  <td className={`${TD_NORMAL} pl-4`}>{record.actualHours.toFixed(record.actualHours % 1 != 0 && 2)} hour(s)</td>
                  <td className={`${TD_NORMAL} pl-4`}>{record.totalVisitNumber} visit(s)</td>
                  <td className={`${TD_LAST} pl-4 ${fold == index && 'rounded-r-none'}`}>
                    <Button variant="outlined" sx={{ marginRight: 2 }} onClick={() => onDisplayWorkingDetail(index, record.trainerId)}>
                      {t('VIEW_DETAIL')}
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <SlideDown closed={fold != index} className="w-full mb-3 bg-white rounded-b-lg p-2">
                      <div className="mb-3 flex">
                        <TextField
                          value={searchDetailKey}
                          label={'Search Visit by Name'}
                          variant="outlined"
                          sx={{ width: 300, background: 'white', marginRight: 2 }}
                          onChange={(value) => setSearchDetailKey(value.target.value)}
                          size="small"
                        />
                        <FormControl fullWidth>
                          <InputLabel>Sort by Scheduled Date</InputLabel>
                          <Select
                            label="Sort By Scheduled Date"
                            sx={{ width: 200 }}
                            value={sortMode}
                            onChange={onChangeSortMode}
                            size="small"
                          >
                            <MenuItem value={"0"}>From Latest</MenuItem>
                            <MenuItem value={"1"}>From Earliest</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {teacherStore.loadingWorkingDetail ? <div className="text-center py-4"><CircularProgress /></div>
                        :
                        <TableContainer sx={{ maxHeight: 440, borderRadius: 2 }}>
                          <Table stickyHeader size="small">
                            <TableHead sx={{ width: '100%', backgroundColor: THEME_COLOR }}>
                              <TableRow>
                                <StyledTableCell>Visit Name</StyledTableCell>
                                <StyledTableCell>Trainee</StyledTableCell>
                                <StyledTableCell align="right">Planned Hours</StyledTableCell>
                                <StyledTableCell align="right">Actual Hours</StyledTableCell>
                                <StyledTableCell align="right">Scheduled Date</StyledTableCell>
                                <StyledTableCell align="right">Scheduled Start Time</StyledTableCell>
                                <StyledTableCell align="right">Scheduled End Time</StyledTableCell>
                                <StyledTableCell align="right">Check In Time</StyledTableCell>
                                <StyledTableCell align="right">Check Out Time</StyledTableCell>
                                {/* <StyledTableCell align="right">Trainee</StyledTableCell> */}
                                {/* <StyledTableCell align="right">Employer</StyledTableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {processedDetailList.filter(v => searchDetailKey === "" ? v.visitName !== "" : v.visitName.toLowerCase().includes(searchDetailKey.toLocaleLowerCase())).length > 0 &&
                                processedDetailList.filter(v => searchDetailKey === "" ? v.visitName !== "" : v.visitName.toLowerCase().includes(searchDetailKey.toLocaleLowerCase()))
                                  .map((v: any, index: number) => (
                                    <StyledTableRow
                                      key={`detail-record_${index}`}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                      <StyledTableCell component="th" scope="row">{v.visitName}</StyledTableCell>
                                      <StyledTableCell component="th" scope="row">
                                        <Link to="/traineeship/overview" state={{ filterSearchKey: v.traineeName }}><span className="hover:text-orange-500 hover:underline">{v.traineeName}</span></Link> 
                                      </StyledTableCell>
                                      <StyledTableCell align="right">{v.plannedHours.toFixed(v.plannedHours % 1 != 0 && 2)} hour(s)</StyledTableCell>
                                      <StyledTableCell align="right">{v.actualHours.toFixed(v.actualHours % 1 != 0 && 2)} hour(s)</StyledTableCell>
                                      <StyledTableCell align="right">{DateUtils.onConvertDateFormat(v.startTime.split('T')[0])}</StyledTableCell>
                                      <StyledTableCell align="right">{v.startTime.split('T')[1]}</StyledTableCell>
                                      <StyledTableCell align="right">{v.endTime.split('T')[1]}</StyledTableCell>
                                      <StyledTableCell align="right">{v.checkInTime ? DateUtils.onConvertDateFormat(v.checkInTime.split('T')[0]) + " " + v.checkInTime.split('T')[1] : "No yet"}</StyledTableCell>
                                      <StyledTableCell align="right">{v.checkOutTime ? DateUtils.onConvertDateFormat(v.checkOutTime.split('T')[0]) + " " + v.checkOutTime.split('T')[1] : "No yet"}</StyledTableCell>
                                      {/* <StyledTableCell align="right">{v.traineeName}</StyledTableCell> */}
                                      {/* <StyledTableCell align="right">{v.employerName}</StyledTableCell> */}
                                    </StyledTableRow>
                                  ))}
                            </TableBody>
                          </Table>
                        </TableContainer>}
                    </SlideDown>
                  </td>
                </tr>
                <Box marginY={1} />
              </>
            ))}
      />
    </ContentLayout>
  )
});

export default WorkingHoursSummaryPage;
